import React from 'react';

function PricingPage() {
  return (
      <>
          <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
          <stripe-pricing-table pricing-table-id="prctbl_1OomwQDISBjxczlLiaBpaX2y"
                                publishable-key="pk_test_51Oie8yDISBjxczlLzH4fo8XiHDj2yO9dfpW0qw9NUdtoPoY1kFtVJ1W1eIXV6osFbtoxyF3frCmNTKsWzmFvfkYu00gG8NDlDS">
          </stripe-pricing-table> </>
  );
}

export default PricingPage;
